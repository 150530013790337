<template>
  <div class="panel">
    <el-form
      ref="outletform"
      :model="outletform"
      label-position="top"
      :rules="rules"
      size="mini"
    >
      <el-row :gutter="20">
        <el-col :span="8" :offset="6">
          <el-form-item prop="percent" label="Шимтгэлийн хувь">
            <el-radio-group v-model="outletform.commission_type">
              <el-radio label="percent">Хувь</el-radio>
              <el-radio label="fixed">Тогтмол дүн</el-radio>
            </el-radio-group>
            <el-input
              v-if="outletform.commission_type === 'percent'"
              v-model="outletform.percent"
              placeholder="Шимтгэлийн хувь оруулна уу"
              ><template slot="append">%</template></el-input
            >
            <el-input
              v-if="outletform.commission_type === 'fixed'"
              v-model="outletform.percent"
              placeholder="Тогтмол дүн"
              ><template slot="append">₮</template></el-input
            >
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Шимтгэлийн түүх">
            <el-button
              class="partner-button"
              type="info"
              size="mini"
              @click="dialogBool = true"
              icon="el-icon-timer"
            >
              Түүх харах</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="!mArrayIncludes(role, ['ops', 'TL', 'CC'])">
        <el-col :span="6" :offset="6">
          <el-form-item label="Хүргэлтийн төлбөрийг манайх авах эсэх">
            <el-checkbox
              :disabled="!mArrayIncludes(role, ['finance'])"
              v-model="outletform.is_get_delivery_charge"
            >
              Хүргэлтийн төлбөрийг манайх авах эсэх
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8" :offset="6">
          <el-form-item prop="vat_type" label="Төрөл">
            <el-select
              @input="vatTypeChange"
              :disabled="!mArrayIncludes(role, ['finance'])"
              v-model="outletform.vat_type"
            >
              <el-option
                v-for="type in types"
                :label="type.label"
                :value="type.value"
                :key="type.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="vat_regno" label="Регистрийн дугаар">
            <el-row>
              <el-input
                :disabled="!mArrayIncludes(role, ['finance'])"
                :maxlength="outletform.vat_type === 'company' ? 7 : 15"
                v-model="outletform.vat_regno"
                :onchange="onChangeRegisterNumber(outletform.vat_regno)"
              >
              </el-input>
              <span style="position: absolute; right: 15px; font-weight: bold;">
                {{ vatName }}
              </span>
            </el-row>
          </el-form-item>
          <el-form-item prop="bank_acount_code" label="Банкны нэр">
            <el-select
              @input="bankNameChange"
              v-model="outletform.bank_acount_code"
              placeholder="Банкны нэр сонгох"
              :disabled="!mArrayIncludes(role, ['finance'])"
            >
              <el-option
                v-for="item in bankCodes"
                :key="item.bank_code"
                :label="item.bank_name_mon"
                :value="item.bank_code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="bank_account_number" label="Дансны дугаар">
            <el-input
              :disabled="!mArrayIncludes(role, ['finance'])"
              :maxlength="outletform.bank_account_code === '050000' ? 10 : 15"
              @input.native="bankNumberChange"
              v-model="outletform.bank_account_number"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="bank_account_name" label="Дансны нэр">
            <el-input
              :disabled="!mArrayIncludes(role, ['finance'])"
              v-model="outletform.bank_account_name"
            ></el-input>
          </el-form-item>
          <el-form-item prop="company_name" label="Байгуулгын нэр">
            <el-input
              :disabled="!mArrayIncludes(role, ['finance'])"
              v-model="outletform.company_name"
              placeholder="Байгуулгын нэр"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="!mArrayIncludes(role, ['ops', 'TL', 'CC'])">
        <el-col :span="12" :offset="6">
          <el-form-item label="Даймонд код">
            <el-input
              v-model="outletform.d_code"
              placeholder="Даймонд код оруулна уу"
              :disabled="
                !mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin', 'finance'])
              "
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6" :offset="6">
          <el-form-item label="Ebarimt-г манайх гаргах эсэх">
            <el-checkbox
              v-model="outletform.ebarimt"
              :disabled="!mArrayIncludes(role, ['finance'])"
            >
              Ebarimt
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form label-position="top" size="mini" :rules="rules" ref="zone">
        <el-row :gutter="20" v-if="!mArrayIncludes(role, ['ops', 'TL', 'CC'])">
          <el-col :span="12" :offset="6">
            <el-form-item prop="desc" label="Зассан шалтгаан">
              <el-input
                type="textarea"
                rows="2"
                v-model="outletform.desc"
                placeholder="Зассан шалтгаан оруулна уу!!!"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20">
        <el-col :span="4" :offset="10">
          <el-button
            type="success"
            size="medium"
            class="full-width"
            @click="updateInformation"
            :disabled="
              !mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin', 'finance'])
            "
            >Хадгалах</el-button
          >
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="Түүх харах" :visible.sync="dialogBool" width="80%" center>
      <span>
        <el-table size="mini" :data="this.addInfo" style="width: 95%">
          <el-table-column prop="desc" label="Тайлбар"> </el-table-column>
          <el-table-column prop="user" label="Хэн"> </el-table-column>
          <el-table-column prop="created_at" label="Хэзээ" width="180" sortable>
          </el-table-column>
          <el-table-column prop="new_percent" label="Шинэ хувь">
          </el-table-column>
          <el-table-column prop="old_percent" label="Хуучин"> </el-table-column>
          <el-table-column prop="new_owner" label="Шинэ х/ажилтан">
          </el-table-column>
          <el-table-column prop="old_owner" label="Хуучин"> </el-table-column>
          <el-table-column prop="new_d_code" label="Шинэ Д/код">
          </el-table-column>
          <el-table-column prop="old_d_code" label="Хуучин"> </el-table-column>
        </el-table>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogBool = false" type="danger"
          >Хаах</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import axios from "axios";
import { getGroups, getUserName } from "../utils/auth";
import { arrayIncludes } from "../helpers/helper";
import services from "../helpers/services";
export default {
  created() {
    this.seeLog();
    this.openFullScreenLoader(true);
    this.getOutletById(this.$route.query._id);
    this.getBankCodes();
    getUserName().then(name => {
      this.user = name.attributes.email.split("@")[0];
    });
  },
  mounted() {
    getGroups().then(data => {
      this.role = data;
    });
  },
  data() {
    return {
      bankName: null,
      loading: null,
      vatName: "",
      role: [],
      outletform: {},
      user: "",
      rules: {
        desc: [
          {
            required: true,
            message: "Зассан шалтгаан оруулна уу",
            trigger: "blur"
          }
        ],
        percent: [
          {
            required: true,
            message: "Шимтгэлийн хувь оруулна уу",
            trigger: "blur"
          }
        ],
        vat_type: [
          {
            required: true,
            message: "Төрөл оруулна уу",
            trigger: "blur"
          }
        ],
        vat_regno: [
          {
            required: true,
            message: "Регистрийн дугаар оруулна уу",
            trigger: "blur"
          }
        ],
        bank_acount_code: [
          {
            required: true,
            message: "Банкны нэр оруулна уу",
            trigger: "blur"
          }
        ],
        bank_account_number: [
          {
            required: true,
            message: "Дансны дугаар оруулна уу",
            trigger: "blur"
          }
        ],
        bank_account_name: [
          {
            required: true,
            message: "Дансны нэр оруулна уу",
            trigger: "blur"
          }
        ]
      },
      dialogBool: false,
      addInfo: [],
      bankCodes: [],
      types: [
        {
          label: "Хувь хүн",
          value: "person"
        },
        {
          label: "Компани",
          value: "company"
        }
      ]
    };
  },
  methods: {
    async onChangeRegisterNumber(vatRegno) {
      if (this.outletform.vat_type === "company" && vatRegno?.length == 7) {
        await services.vatRegno(vatRegno).then(res => {
          if (res?.data) {
            this.vatName = res.data;
          } else {
            this.vatName = "";
          }
        });
      }
    },
    async getBankAccountName(bankAccountNumber) {
      this.openFullScreenLoader(true);
      try {
        const body = {
          brand_name: "TokTok",
          account_number: bankAccountNumber
        };
        const res = await axios.post(
          "https://rest.toktok.mn/payment/corporate/accountName",
          body
        );
        if (res.data.status === "success") {
          this.outletform.bank_account_name = res.data.response.custFirstName
            ? res.data.response.custFirstName
            : res.data.response.custLastName;
        }
        this.openFullScreenLoader(false);
      } catch (err) {
        this.openFullScreenLoader(false);
      }
      this.$forceUpdate();
    },
    async bankNumberChange(event) {
      if (
        event.target.value.length === 10 &&
        this.outletform.bank_acount_code === "050000"
      ) {
        this.getBankAccountName(event.target.value);
      }
    },
    async vatTypeChange(value) {
      if (value === "company" && this.vatRegno?.length == 7) {
        await services.vatRegno(this.vatName).then(res => {
          if (res?.data) {
            this.vatName = res.data;
          } else {
            this.vatName = "";
          }
        });
      }
    },
    async bankNameChange(value) {
      if (value === "050000") {
        if (this.outletform.bank_account_number.length === 10) {
          this.getBankAccountName(this.outletform.bank_account_number);
        }
      }
    },
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "Харилцагчийн мэдээлэл татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    nextpage() {
      this.$router.push({
        path: "contractInfo",
        query: {
          _id: this.$route.query._id
        }
      });
    },
    getOutletById(id) {
      const payload = { includeFields: [] };
      service.getOutletById(id, payload).then(outletResponse => {
        this.outletform = outletResponse.data.data;
      });
      this.openFullScreenLoader(false);
    },
    async updateInformation() {
      this.$refs.outletform.validate(async valid => {
        if (valid) {
          this.outletform._id = this.$route.query._id;
          this.outletform.user = this.user;
          this.outletform.action = "updateAdditionalInformation";
          this.outletform.register_number = this.outletform.vat_regno;
          await service
            .editPartnerfinanceInfo(this.$route.query._id, this.outletform)
            .then(response => {
              this.$router.push({
                path: "contractInfo",
                query: { _id: this.$route.query._id }
              });
              this.$notify({
                title: "Success",
                message: response.data === "" ? "амжилттай" : "амжилттай",
                type: "success"
              });
            });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    seeLog() {
      service
        .getOutletAdditionalInfoHistory({ outlet_id: this.$route.query._id })
        .then(response => {
          this.addInfo = response.response;
        });
    },
    getBankCodes() {
      service.getBankCodes().then(res => {
        this.bankCodes = res.response;
      });
    }
  }
};
</script>
